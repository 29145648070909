

#createmessage{
    height: 50px;
}

.messagebox{
    width: 300px;
}
@media only screen and (max-width: 1100px){
    body{
        background-color: lightblue;
    }
    button{
        height: 130px;
        width:95vw;
        font-size: 50px;
        cursor: pointer;
        
    }
    input{
        height: 130px;
        font-size: 50px;
        cursor: pointer;
    }
    div{
        font-size: 30px;
    }
    #createmessage{
        height: 100px;
    }
    .messagebox{
        width: 90vw;
        background-color: orange;
        font-size: 50px;
    }

    .messagesquare{
        font-size: 50px;
    }

    input[type=submit]{
        width: 500px;
       

    }
}